<template>
  <section class="card d-flex align-center justify-center">
    <div>
      <h1 class="card-title">{{translate('no_item_found','No Item found')}}</h1>
      <p class="card-description">
        {{translate('press_back_to_menu','Press on the “Back to menu” button to go back and choose from our menu')}}
      </p>
    </div>
  </section>
</template>
<script>
import itemMixin from '@/mixins/item'
import translationMixin from '@/mixins/translations'
export default {
  name: "BaseEmpty",
  mixins: [itemMixin, translationMixin, global],
};
</script>
<style lang="scss" scoped>
.card {
    height: 75vh;
  &-title {
    font-weight: bold;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    color: #2b388f;
  }
  &-description {
    font-weight: normal;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: #000000;
    width: 733px;
  }
}
</style>