<template>
    <v-card class="card bg-grey" flat>
        <section class="d-flex justify-center">
            <v-container class="d-flex justify-space-between align-center px-1 mx-1">
                <v-img max-width="117" :src="logoURI" />
                <v-btn depressed text color="primary" class="btn-take-out d-flex justify-space-between" @click.stop="$emit('on-takeout')">
                    <v-img max-width="64" src="@/assets/img/lang/takeout.svg" />                    
                    <span class="mx-8">{{translate(getOrderType, getOrderType).toUpperCase()}}</span> 
                    <v-icon size="69" color="primary" class="btn-take-out-icon">mdi-pencil-circle</v-icon>
                </v-btn>
                <v-btn @click.stop="$emit('confirm-cart-reset')" depressed color="primary" class="btn-reset">{{translate('reset','Reset').toUpperCase()}}</v-btn>    
            </v-container>
        </section>
    </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import translationMixin from '@/mixins/translations'
export default {
    name:'CartHeader',
    mixins: [translationMixin],
    computed: {
        ...mapGetters({
            getOrderType: 'app/getOrderType',
            menus: 'cart/getMenu',
            concept: 'concept/getConcept'
        }),        
        logoURI() {
        return this.concept['logo-uri']
        },
    }
}
</script>
<style lang="scss" scoped>
.card{
    height: 187.77px;
    // background: #E5E5E5;
    // box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 
    // 0px 16px 24px rgba(0, 0, 0, 0.04),
    //  0px 4px 8px rgba(0, 0, 0, 0.04),
    //   0px 0px 1px rgba(0, 0, 0, 0.04) !important;
      section{
          height: 100% !important;
          .container{
              height: 100% !important;
              max-width: 1000px !important;
              .btn-take-out{
                //   width: 317px;
                  height: 117px;
                  background: #FFFFFF;
                  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
                  border-radius: 20px;
                  font-weight: bold;
                  font-size: 34px;
                  text-transform: capitalize;
                  &-icon{

                  }
              }
              .btn-reset{
                  width: 163px;
                  height: 86.91px;
                  border-radius: 178px;
                  font-weight: 900;
                  font-size: 24px;
                  color: #FFFFFF;
              }
        }
    }
}
</style>