<template>
    <v-row justify="center">
        <v-dialog value="1" fullscreen  eager transition="dialog-bottom-transition">
            <v-card class="bg-grey">
                <CartHeader @confirm-cart-reset="isCartReset = true" @on-takeout="isTakeOut = true"/>
                <CartTakeOutOption v-if="isTakeOut" v-model="isTakeOut" @on-selected="takeOutOption" />
                <BaseMessage v-if="isCartReset" v-model="isCartReset" @confirm="onReset({router: $router, clear: clear})" :title="translate('restart_order','Restart Order')" confirm-label="Clear">
                    {{translate('confirm_reset_text','Are you sure you want to reset? Your orders will be removed and you will be taken to the homepage')}}
                </BaseMessage>
                <section class="d-flex justify-center">
                    <v-container class="mx-1 px-1 mt-10">
                        <template v-if="items.length">
                            <CartItem v-for="(item, index) in items" @input="updateQuantity" :item="item" :key="index" :index="index" @remove-item="remove"/>
                        </template>
                        <template v-else >
                            <BaseEmpty />
                        </template> 
                    </v-container>
                </section>
                <BaseFooter @next-step="$router.push({name:'menu.upsell'})" />
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import itemMixin from '@/mixins/item'
import translationMixin from '@/mixins/translations'
import CartHeader from '@/components/cart/CartHeader.vue';
import CartItem from '@/components/cart/CartItem.vue';
import CartTakeOutOption from '@/components/cart/CartTakeOutOption.vue';
import BaseFooter from '@/components/base/BaseFooter.vue';
import BaseEmpty from '@/components/base/BaseEmpty.vue';
import BaseMessage from '@/components/base/BaseMessage';
import { mapGetters, mapMutations } from 'vuex';
import global from '@/mixins/global'

export default {
    name:'CartPage',
    components:{
        CartHeader,
        BaseMessage,
        CartItem,
        BaseFooter,
        BaseEmpty,
        CartTakeOutOption
    },
    mixins: [itemMixin, translationMixin, global],
    data: ()=>({
        isCartReset: false,
        isTakeOut: false
    }),
    computed:{
        ...mapGetters({
            items: 'cart/items'
        })  
    },    
    methods:{
        ...mapMutations({
            increase: 'cart/increase',
            decrease: 'cart/decrease',
            updateQuantity: 'cart/updateQuantity',
            remove: 'cart/remove',
            setOrderType: 'app/setOrderType',
            clear: 'cart/clear'
        }),
        takeOutOption(type){            
            this.setOrderType(type)            
            this.isTakeOut = !this.isTakeOut
        }        
    }
}
</script>
<style lang="scss" scoped>
.container{
    max-width: 1000px;
}
</style>