<template>
    <v-row justify="center">
        <v-dialog v-model="model" width="889">
            <v-card class="px-5 py-5 page">
                <v-card-title>
                    <v-row justify="center" align="center">
                        <v-col></v-col>
                        <v-col cols="6">
                            <h1 class="page-title">{{translate('please_select','PLEASE SELECT')}}</h1>
                        </v-col>
                        <v-col>
                            <section class="d-flex justify-end">
                                <v-btn width="79" height="79" class="" color="#B5B5B5" fab elevation="0" @click="model = false">
                                    <v-icon color="white" size="35">mdi-close</v-icon>
                                </v-btn>
                            </section>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text class="d-flex justify-space-around mt-7">
                    <v-card height="424.26px" width="353.31px" class="card d-flex flex-column justify-center align-center" @click.stop="$emit('on-selected', 'to-go')" flat >
                        <div>
                            <img class="card-image" src="@/assets/img/cart/take-out.svg" />
                            <h1 class="card-title nt">{{translate('to-go','TAKE OUT').toUpperCase()}}</h1>
                        </div>
                    </v-card>
                    <v-card height="424.26px" width="353.31px" class="card d-flex flex-column justify-center align-center" @click.stop="$emit('on-selected', 'eat-in')" flat >
                            <img class="card-image" src="@/assets/img/cart/dine-in.svg" />
                            <h1 class="card-title nt">{{translate('eat-in','DINE IN').toUpperCase()}}</h1>
                    </v-card>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import translationMixin from '@/mixins/translations'
export default {
    name:'CartTakeOutOption',
    props:{
        value:{
            type:Boolean,
            default: false
        }
    },
    mixins: [translationMixin],
    computed:{
        model:{
            get(){
                return this.value
            },
            set(v){
                this.$emit('input', v)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.card{
    width: 353.31px !important;    
    background: var(--v-primary-base) !important;
    border-radius: 20px !important;
    &-image{
        width: 154.88px;
        height: 154.88px;
        display: block;        
    }
    &-title{
        display: block;                
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 54px;   
        margin-top: 52px;     
        text-align: center;
        color: #FFFFFF;
    }
}
.page{
    &-title{        
        font-style: normal !important;
        font-weight: 900 !important;
        font-size: 36px !important;
        line-height: 54px !important;
        text-align: center !important;
        color: var(--v-primary-base) !important;

    }
}
</style>